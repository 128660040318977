import { defineStore } from "pinia";
import type {
  Contract,
  ContractAllOfSupply,
  PublicTariffWithSummarizedPriceRanges as PublicTariff,
  PublicTariffSubTypeEnum,
} from "~/src/generated-sources/public";
import { oneYearAgo } from "~/tests/unit/dates";
import type { ContractType } from "~/types/shared/contract-type";
import type {
  AlternativeImpactContract,
  ImpactCategories,
  ImpactContentId,
  ImpactIcons,
} from "~/types/shared/impact-calculator";
import changeDateByYears from "~/utils/changeDateByYears";
import getTariffForContract from "~/utils/customer-area/getTariffForContract";
import calculateAmountOfLivesImproved from "~/utils/impact/calculateAmountOfLivesImproved";
import { calculateContractsCo2Reduction } from "~/utils/impact/calculateCarbonDioxideSavingsInKG";
import calculateEnergyTransitionValueInEur from "~/utils/impact/calculateEnergyTransitionValueInEur";
import calculateRecommendationPosition from "~/utils/impact/calculateRecommendationPosition";

/** We append this template to each contract since this structure
 * is necessary for the KPI calculation.
 */
const supplyTemplate: ContractAllOfSupply = {
  confirmedBeginAt: oneYearAgo(),
  address: {
    city: "",
    country: "",
    houseNumber: "",
    street: "",
    zipCode: "",
    type: "private",
  },
};

interface Coordinates {
  x: number;
  y: number;
}

export const useImpactCalculatorStore = defineStore("ImpactCalculatorStore", () => {
  /** This variable is used as a config file for the impact calculator.
   * You can define initial values like annualConsumption or modify the
   * icons shown in the graph. It is also used to list out all contract types
   * in the TariffPanel.
   *
   * Only if a template contract is added to the impactContracts array, it will be
   * used in the calculation.
   */
  const impactContractTemplates = ref<AlternativeImpactContract[]>([
    {
      id: "strom",
      name: "Wirklich Ökostrom",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "strom",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "strom"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "gas",
      name: "Wirklich Ökogas",
      type: "gas",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "gas",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "gas"],
        e: ["investition", "photovoltaik", "foerderung", "ruebe", "biogas"],
        r: ["fair", "gemeinwohl", "platz1", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "autostrom",
      name: "Wirklich Autostrom",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "autostrom",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "autostrom"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "waermepumpenstrom",
      name: "Wärme­pumpen­strom",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "htnt",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "waermepumpe"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "htnt",
      name: "HT/NT",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "htnt",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "htnt"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "nachtspeicherstrom",
      name: "Nachtspeicher­strom",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "htnt",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "nachtspeicher"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "mieterstrom",
      name: "Wirklich Mieter­strom",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "mieterstrom",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "mieterstrom"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
    {
      id: "eigenstrom",
      name: "Wirklich Eigenstrom",
      type: "power",
      state: "Terminated",
      annualConsumption: { value: 2400, unit: "kWh" },
      icon: "eigenstrom",
      icons: {
        w: ["biogasanlage", "dung", "gruppe", "lichtkochen", "wende", "eigenstrom"],
        e: ["investition", "photovoltaik", "foerderung", "wasserkraft", "landschaft"],
        r: ["fair", "gemeinwohl", "qualitaet", "co2"],
      },
      supply: { ...supplyTemplate },
    },
  ]);

  /** The impact in the form of KPIs. */
  const currentImpactInNumbers = ref({
    energyInvestment: 0,
    lifeImpact: 0,
    co2Reduction: 0,
  });

  const calculateCurrentImpactInNumbers = () => {
    currentImpactInNumbers.value = {
      energyInvestment: calculateEnergyTransitionValueInEur(
        impactContracts.value,
        amountOfRecommendations.value,
      ),
      lifeImpact: calculateAmountOfLivesImproved(
        amountOfRecommendations.value,
        impactContracts.value,
      ),
      co2Reduction: calculateContractsCo2Reduction(
        impactContracts.value,
        amountOfRecommendations.value,
      ),
    };
  };

  const recalculateRecommendationPositions = () => {
    graph.value.recommendations = [];
    graph.value.recommendations = calculateRecommendationPosition(
      amountOfRecommendations.value,
    );
  };

  const changeContractYears = (deltaYears: number) => {
    impactContracts.value.forEach((contract) => {
      const supplyStart = contract.supply.confirmedBeginAt;

      if (supplyStart) {
        const newDate = changeDateByYears(contract.supply.confirmedBeginAt, deltaYears);
        contract.supply.confirmedBeginAt = newDate;
      }
    });
  };

  const decrementAmountOfYearsAsCustomer = () => {
    if (amountOfYearsAsCustomer.value > 0) {
      changeContractYears(1);
      amountOfYearsAsCustomer.value--;
    }
    zoomInGraph();
  };
  const incrementAmountOfYearsAsCustomer = () => {
    changeContractYears(-1);
    amountOfYearsAsCustomer.value++;
    zoomOutGraph();
  };

  const decrementAmountOfRecommendations = () => {
    if (amountOfRecommendations.value > 0) {
      amountOfRecommendations.value--;
      recalculateRecommendationPositions();
    }
  };
  const incrementAmountOfRecommendations = () => {
    amountOfRecommendations.value++;
    recalculateRecommendationPositions();

    if (graph.value.settings.zoom > 0.4) {
      setZoom(0.4);
    }
  };

  type GraphType = {
    settings: {
      x: number;
      y: number;
      rays: number;
      zoom: number;
    };
    activeIconName: ImpactContentId;
    flower: {
      [key in ImpactCategories]: string[][];
    };
    iconsPool: ImpactIcons;
    dotsPool: ImpactIcons;
    recommendations: Coordinates[];
  };

  const graph = ref<GraphType>({
    settings: {
      x: 80,
      y: 90,
      rays: 7,
      zoom: 1.5,
    },
    activeIconName: "strom", // TODO: is it okay to leave it like this? it's supposed to be a placeholder
    flower: {
      w: [],
      e: [],
      r: [],
    },
    iconsPool: {
      w: [],
      e: [],
      r: [],
    },
    dotsPool: {
      w: [],
      e: [],
      r: [],
    },
    recommendations: [] as Coordinates[],
  });

  const addEmptyArraysToFlower = () => {
    const emptyArraysCount = graph.value.settings.rays;
    // create empty arrays for each category
    for (let i = 0; i < emptyArraysCount; i++) {
      graph.value.flower.w.push([]);
      graph.value.flower.e.push([]);
      graph.value.flower.r.push([]);
    }
  };

  addEmptyArraysToFlower();

  const impactContracts = ref<AlternativeImpactContract[]>([]);

  // TODO: GraphModal is a horrible name, refactor once it works

  const getActiveIconForGraphModal = () => {
    return graph.value.activeIconName;
  };

  const amountOfYearsAsCustomer = ref(0);

  const amountOfRecommendations = ref(0);

  const clearIconsPool = () => {
    graph.value = { ...graph.value, iconsPool: { w: [], e: [], r: [] } };
  };

  const clearDotsPool = () => {
    graph.value = { ...graph.value, dotsPool: { w: [], e: [], r: [] } };
  };

  const clearFlower = () => {
    graph.value = {
      ...graph.value,
      flower: {
        w: [],
        e: [],
        r: [],
      },
    };

    addEmptyArraysToFlower();
  };

  const initializeForNonCustomer = () => {
    amountOfRecommendations.value = 0;
    amountOfYearsAsCustomer.value = 1;

    // initialize with one (sample) contract
    const initializedContracts: AlternativeImpactContract[] = [];
    initializedContracts.push({ ...impactContractTemplates.value[0], state: "Active" });
    impactContracts.value = initializedContracts;

    // important: also set the state in impactContractTemplates so it's displayed correctly in the TariffPanel
    impactContractTemplates.value[0].state = "Active";
  };

  const initializeForCustomer = (
    recommendationsCount: number,
    customerYears: number,
    initialContracts: Contract[],
    tariffs: PublicTariff[],
  ) => {
    amountOfRecommendations.value = recommendationsCount;
    amountOfYearsAsCustomer.value = customerYears;

    /** TODO: decide to implement this or not:
     * If a user is logged in and has exactly one contract, set
     * the first contract in impactContractTemplates to active.
     */

    const processedContracts: Contract[] = [];
    initialContracts.forEach((contract) => {
      if (contract.state === "Active") {
        // We can't just pass the contract because it will be copied by reference
        processedContracts.push(JSON.parse(JSON.stringify(contract)));
      }
    });

    impactContracts.value = processedContracts.map((contract) => {
      const tariff = getTariffForContract(tariffs, contract);

      return {
        annualConsumption: contract.annualConsumption,
        lifecycle: contract.lifecycle,
        state: contract.state,
        supply: contract.supply,
        type: contract.type,

        // TODO: Needs id/type mapping
        name: contract.name || contract.id,
        // TODO: Needs id/type mapping
        id: contract.id,
        // TODO: Needs icon/type mapping
        icon: getContractIcon(contract.type, tariff?.subType),
        // TODO: Needs coordination mapping
        icons: {
          w: getImpactIcons(contract.type, "w"),
          e: getImpactIcons(contract.type, "e"),
          r: getImpactIcons(contract.type, "r"),
        },
      };
    });

    recalculateRecommendationPositions();
    calculateCurrentImpactInNumbers();
  };

  const getContractIcon = (
    contractType: ContractType,
    tariffSubtype?: PublicTariffSubTypeEnum,
  ) => {
    // non-regular contract types
    if (tariffSubtype) {
      switch (tariffSubtype) {
        case "own":
          return "eigenstrom";
        case "carPower":
        case "carPowerPlus":
          return "autostrom";
        case "heatPump":
        case "nightStorageHeating":
          return "htnt";
      }
    }
    // regular contract types
    switch (contractType) {
      case "gas":
        return "gas";
      case "power":
        return "strom";
      case "eMobility":
        return "autostrom";
      case "tenantPower":
      case "tenantPowerShadow":
        return "mieterstrom";
    }
  };

  const changeAnnualConsumption = (contractId: string, newAnnualConsumption: number) => {
    const contract = impactContracts.value.find((c) => c.id === contractId);

    if (contract) {
      contract.annualConsumption.value = newAnnualConsumption;
    }
  };

  const getImpactIcons = (contractType: ContractType, category: ImpactCategories) => {
    const getIcons = (id: string) => {
      const icons = impactContractTemplates.value.find((contract) => contract.id === id)
        ?.icons[category];
      return icons || [];
    };

    switch (contractType) {
      case "power":
        return getIcons("strom");
      case "gas":
        return getIcons("gas");
      case "eMobility":
        return getIcons("autostrom");
      case "tenantPower":
      case "tenantPowerShadow":
        return getIcons("mieterstrom");
      default:
        return [];
    }
  };

  const reset = () => {
    amountOfRecommendations.value = 0;
    amountOfYearsAsCustomer.value = 1;
    impactContracts.value = [];
    clearFlower();
  };

  const zoomStep = 0.1;

  const zoomInGraph = () => {
    if (graph.value.settings.zoom <= 2.5) {
      graph.value.settings.zoom += zoomStep;
    }
  };
  const zoomOutGraph = () => {
    if (graph.value.settings.zoom >= 0.25) {
      graph.value.settings.zoom -= zoomStep;
    }
  };

  const setZoom = (zoom: number) => {
    graph.value.settings.zoom = zoom;
  };

  const addContract = (contract: AlternativeImpactContract) => {
    impactContracts.value.push(contract);
  };

  const removeContract = (contractId: string) => {
    impactContracts.value = impactContracts.value.filter((c) => c.id !== contractId);
  };

  return {
    addContract,
    amountOfYearsAsCustomer,
    amountOfRecommendations,
    calculateCurrentImpactInNumbers,
    changeAnnualConsumption,
    clearIconsPool,
    clearDotsPool,
    clearFlower,
    currentImpactInNumbers,
    decrementAmountOfRecommendations,
    decrementAmountOfYearsAsCustomer,
    getActiveIconForGraphModal,
    graph,
    impactContracts,
    incrementAmountOfRecommendations,
    incrementAmountOfYearsAsCustomer,
    initializeForCustomer,
    initializeForNonCustomer,
    removeContract,
    reset,
    zoomInGraph,
    zoomOutGraph,
    impactContractTemplates,
  };
});
