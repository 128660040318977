<template>
  <circle class="dot" cx="50" cy="50" r="4" />
</template>

<style lang="scss">
.dot {
  fill: #ccc;
  stroke: transparent;
}
</style>
