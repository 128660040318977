import type { ModalContent } from "~/types/shared/impact-calculator";

export const modalContent: ModalContent[] = [
  {
    id: "co2",
    headline: "Du verlierst bis zu 25 % deines CO₂-Gewichts.",
    text: "Energie ist dein Shortcut für ein CO₂-reduziertes Leben. Mit Wirklich besserer Energie senkst du deinen CO₂-Fußabdruck um rund ein Viertel! Einfacher geht Klimaschutz nicht – du lässt die richtige Energie einfach die Arbeit machen. Und das ist verdammt wichtig. Schließlich sind etwa 85 % der deutschen Treibhausgas-Emissionen energiebedingt.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/magazin/artikel/so-verlierst-du-deine-co2-kilos/",
    },
    color: "#ffd750",
    images: [
      {
        src: "/impact-calculator/Polarstern_Grafik_CO2Fußabdruck2020_Headline_1200x628Px_201102.jpg",
        alt: "CO2 Fußabdruck 1",
        width: "1200",
        height: "628",
      },
    ],
  },
  {
    id: "gemeinwohl",
    headline: "Du unterstützt gemeinwohl orientiertes Wirtschaften.",
    text: "Echter Klimaschutz braucht ein anderes Wirtschaften, denn Bad Business ist auch schlecht fürs Klima. Polarstern ist daher Teil der Gemeinwohl-Ökonomie, zertifiziert durch B Corp und Mitglied im Social Entrepreneurship Netzwerk Deutschland (SEND). Wir engagieren uns für eine gerechte Wirtschaftskultur, ohne Gewinnmaximierung und Gier. Deine Entscheidung für Polarstern hilft dieser Bewegung. Als Beweis, dass gemeinwohlorientiertes Wirtschaften gewollt ist – und Gewinn bringt: für die Umwelt und die Gesellschaft.",
    cta: {
      text: "Mehr erfahren",
      url: " https://www.polarstern-energie.de/social-business/",
    },
    color: "#ffd750",
    images: [
      {
        src: "/impact-calculator/Gemeinwohlorientiertes_Wirtschaften_1800.jpg",
        alt: "Wasserkraft",
        width: "1800",
        height: "1103",
      },
    ],
  },
  {
    id: "fair",
    headline: "Gemeinsam funktioniert's einfach besser.",
    text: "Alleingänge bringen nichts. Deshalb kooperieren wir mit Menschen, die unseren Wunsch nach Veränderung teilen: mit Energiepartnern in anderen Ländern, gleichgesinnten Unternehmen anderer Branchen und Netzwerken. Außerdem halten wir gerne Vorträge und veranstalten Events. Das Klima kennt keine Grenzen, deshalb müssen sie auch raus aus den Köpfen. Du bist Teil dieser Bewegung.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/social-business/landkarte/",
    },
    color: "#ffd750",
    images: [
      {
        src: "/impact-calculator/Polarstern_TeamWirklich_TeamBild.jpg",
        alt: "Gemeinsam funktionierts",
        width: "1920",
        height: "1200",
      },
    ],
  },
  {
    id: "platz1",
    headline: "Wirklich Ökogas ist auf Platz 1!",
    text: "Wirklich Ökogas setzt die höchsten Standards in der nachhaltigen Erzeugung. Deshalb sind wir auf Platz 1 in der Ökogas-Bestenliste von Utopia – dem Nachhaltigkeitsportal schlechthin. Das finden unsere Privatkunden gut, und große Marken wie VAUDE und Rapunzel vertrauen der Nummer 1, um ihre Produkte ökologisch zu erzeugen. Unsere Biogasanlage ist natürlich ebenso zertifiziert. Das Label Naturemade garantiert Klimaneutralität und den hundertprozentigen Einsatz von erneuerbaren Energien. Der TÜV Nord bestätigt es auch noch mal. Doppelt hält besser!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/magazin/artikel/woran-du-wirklich-gutes-oekogas-erkennst/",
    },
    color: "#ffd750",
    images: [
      {
        src: "/impact-calculator/Platz_eins_Gas_1800px.jpg",
        alt: "Platz 1 Gas",
        width: "1800",
        height: "1000",
      },
    ],
  },
  {
    id: "qualitaet",
    headline: "Qualität. Mit Siegel!",
    text: "Unsere Energie ist mehrfach ausgezeichnet. Wirklich Ökostrom trägt das strengste Ökolabel im Markt, das Grüner Strom-Label, und wird vielfach empfohlen von Öko-Test genauso wie von renommierten Institutionen wie Robin Wood, Utopia und EcoTopTen. Warum? Weil wir wirklich unabhängig sind, die Energiewende mehr fördern als andere und nur Tarife aus 100 % erneuerbarer Energie anbieten. Das gibt's nicht oft im Markt. Mit Polarstern unterstützt du die Energiewende – nicht nur ein bisschen, sondern wirklich!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/awards-und-siegel/",
    },
    color: "#ffd750",
    images: [
      {
        src: "/impact-calculator/PST_Grafik_ImpactgrafikPolarstern_GrafikenDetail_Siegelleiste_v4.png",
        alt: "Qualitaet Siegel",
        width: "1616",
        height: "253",
      },
    ],
  },
  {
    id: "biogasanlage",
    headline: "Zusammen befähigen wir Menschen zu mehr Selbstbestimmung und Wachstum.",
    text: "Dank unseres Wachstums unterstützen wir immer mehr Energiewende-Initiativen in Entwicklungsländern, so auch in Madagaskar. Dort haben wir mit unserem Partner Africa GreenTec die erste PV-Freiflächenanlage zur Stromversorgung eines großen Dorfes errichtet. Es ist ein wichtiger Schritt für die nachhaltige Entwicklung vor Ort. Die Energieversorgung bietet den Bewohner:innen Chancen, die lokale Wirtschaft zu stärken, ihre Bildung zu verbessern, genauso wie ihre Gesundheit. So werden Fluchtursachen schon an der Wurzel angepackt und das Klima geschützt. Nach dem erfolgreichen Pilotprojekt im Dorf Mahavelona realisieren wir bereits in weiteren Dörfern und Kleinstädten in Madagaskar eine solare Stromversorgung.",
    cta: {
      text: "Mehr von unserem Partner vor Ort erfahren",
      url: "https://www.polarstern-energie.de/magazin/artikel/polarstern-in-madagaskar-energiewende-im-container/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Weltweit_Madagaskar_Engagement_1.jpg",
        alt: "Weltweit Madagaskar Dorf",
        width: "1920",
        height: "1440",
      },
      {
        src: "/impact-calculator/Weltweit_Madagaskar_Engagement_2.jpg",
        alt: "Weltweit Madagaskar Dorf",
        width: "1920",
        height: "1440",
      },
      {
        src: "/impact-calculator/polarstern-madagaskar_1.jpeg",
        alt: "Weltweit Madagaskar Dorf",
        width: "1800",
        height: "1350",
      },
      {
        src: "/impact-calculator/polarstern-madagaskar_2.jpeg",
        alt: "Weltweit Madagaskar Dorf",
        width: "1800",
        height: "1350",
      },
      {
        src: "/impact-calculator/polarstern-madagaskar_3.jpeg",
        alt: "Weltweit Madagaskar Dorf",
        width: "1800",
        height: "1350",
      },
    ],
  },
  {
    id: "dung",
    headline: "Was wegmuss, wird zu sauberer Energie.",
    text: "Biogasanlagen sind in vielen ländlichen Regionen in den Entwicklungsländern perfekt, um Energie zu erzeugen: Sie sind robust, wartungsarm und erschwinglich. Für ihren Betrieb benötigt man nur Dung, Fäkalien und Küchenabfälle. Die Kosten für andere Energieträger wie Holz oder Petroleum fallen weg. Nebenbei sorgst du quasi für den Erhalt der Wälder – die als CO₂-Speicher auch für uns in Deutschland lebenswichtig sind. Klimaschutz und Entwicklung in einem!",
    cta: {
      text: "YouTube-Video Das bewirken unsere Kunden in Kambodscha",
      url: "https://www.youtube.com/watch?v=Tz17a1njjF4",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Biogasanlagen_1800.jpg",
        alt: "Biogasanlagen",
        width: "1800",
        height: "1200",
      },
    ],
  },
  {
    id: "gruppe",
    headline: "Energiewende an einem Strang.",
    text: "Alleingänge bringen nichts. Unser Ziel ist es, mit unseren weltweiten Energiewende-Initiativen die lokale Wirtschaft zu unterstützen. Die Menschen vor Ort sollen wirtschaftlich auf eigenen Beinen stehen, statt langfristig auf Spenden angewiesen zu sein. Das funktioniert, weil wir sie als Partner betrachten. Sie investieren bzw. kaufen selbst die erzeugte Energie zu Preisen, die weit unterhalb der bisherigen Energieerzeugung (Dieselgeneratoren und Feuerholz) liegt. Es geht uns darum, eine Spirale der wirtschaftlichen Entwicklung in Kraft zu setzen, die auch dem Klimaschutz dient und Fluchtursachen bekämpft. Wir steigern die lokale Produktivität, schaffen Arbeitsplätze und verbessern die Einkommen – zusätzlich sparen wir tonnenweise CO2, unterstützen verbesserte Gesundheits- und Bildungsangebote. Kurzum: Wir ermöglichen ihnen ein besseres Leben. Und du hilfst mit!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/weltweit/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Energiewende_Strang_1__ODER_Wechsel_Energiewende_1800.jpg",
        alt: "Energiewende Strang 1",
        width: "1800",
        height: "1200",
      },
      {
        src: "/impact-calculator/Energiewende_Strang_2__ODER_Wechsel_Energiewende_1800.JPG",
        alt: "Energiewende Strang 2",
        width: "1800",
        height: "1350",
      },
      {
        src: "/impact-calculator/Energiewende_Strang_zuBearbeitendazuklein_1800_output.jpg",
        alt: "Energiewende Strang 3",
        width: "1800",
        height: "1312",
      },
      {
        src: "/impact-calculator/Weltweit_Madagaskar_Laden_2_Copyright_AfricaGreenTec_desktop.JPG",
        alt: "Energiewende Strang 4",
        width: "1920",
        height: "1200",
      },
      {
        src: "/impact-calculator/csm_Madagaskar_PVAnlage_ohneMenschen_Bau_Desktop.jpg",
        alt: "Energiewende Strang 5",
        width: "1920",
        height: "1200",
      },
    ],
  },
  {
    id: "lichtkochen",
    headline: "Deine Energie verbessert Lebensverhältnisse – weltweit.",
    text: "Seit unserer Gründung 2011 unterstützen wir Familien in Kambodscha beim Bau einer Biogasanlage. Damit können sie auf einem Gasherd kochen und teilweise auch Licht erzeugen. Das verändert ihr Leben. Wirklich: Denn bis zum Bau der Biogasanlage haben die Familien üblicherweise auf Feuerstellen gekocht. Die häusliche Luftverschmutzung durch Feuerrauch zählt laut WHO zu den größten Gesundheitsrisiken in Entwicklungsländern. Mit einer Biogasanlage bleibt also die Luft rein – und die Lunge auch. Und im Licht der Gaslampen können die Tage länger genutzt werden.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/magazin/artikel/wie-eine-polarstern-mitarbeiterin-den-impact-der-eigenen-arbeit-erlebt/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Licht_Luft_1_1800.jpg",
        alt: "Licht Luft 1",
        width: "1800",
        height: "1200",
      },
      {
        src: "/impact-calculator/Licht_Luft_2_1800.jpg",
        alt: "Licht Luft 2",
        width: "1800",
        height: "1200",
      },
      {
        src: "/impact-calculator/Licht_Luft_3.JPG",
        alt: "Licht Luft 3",
        width: "1920",
        height: "1200",
      },
    ],
  },
  {
    id: "wende",
    headline: "Dein Wechsel fördert die Energiewende weltweit.",
    text: "Für jede:n Kund:in investieren wir jährlich 20 Euro in die weltweite Energiewende. Aktuell unterstützen wir Initiativen in Kambodscha und Madagaskar. Dort bauen wir lokale Biogasanlagen für Familien bzw. PV-Kraftwerke für ganze Dörfer und Kleinstädte. So steigen die Menschen auf erneuerbare Energien um und schützen das Klima. Denn ist sind die Anlagen in Betrieb, ist ihre Energieversorgung sauber und günstig. Und, sie können die Energie vielfältig nutzen: in der Landwirtschaft, zum Lernen und Arbeiten oder auch zum Kochen. Die Folge: mehr Lebensqualität vor Ort, mehr Klimaschutz weltweit. Jedes weitere Polarstern-Jahr von dir ist der Spatenstich für die nächste Anlage!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/weltweit/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Polarstern_Grafik_Weltweit_KambodschaMadagaskar_1920x1200Pixel.png",
        alt: "Wasserkraft",
        width: "1920",
        height: "1200",
      },
    ],
  },
  {
    id: "biogas",
    headline: "Werte im Wärmemarkt.",
    text: "Wenn wir mit Energie die Welt verändern, dann tun wir das werteorientiert. Das heißt: wirklich transparent und glaubwürdig und immer zu 100 %. Und so kommen die im Gasmarkt üblichen Klimatarife, hinter denen nicht mal ein Hauch Biogas steckt, sondern die verursachten Emissionen nur ausgeglichen werden, genauso wenig durch uns in den Markt, wie Ökogas aus Gülle. Denn das basiert auf Massentierhaltung. Und das lehnen wir strikt ab. Anders ist das mit biogenen Rest- und Abfallstoffen oder auch mit Energiepflanzen, sofern sie sinnvoll in die Fruchtfolge integriert und an die lokale Bodenbeschaffenheit angepasst sind. Das macht Sinn!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekogas/biogasanlage/",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Organische_Reststoffe_1800.jpg",
        alt: "Organische Reststoffe",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "foerderung",
    headline: "Eine Investition, die vielerorts wirkt.",
    text: "Echte Energiewende funktioniert nur, wenn sie weltweit passiert. Deshalb investieren wir für jede Kilowattstunde, die du verbrauchst, und für jedes Jahr, das du bei uns bist, weltweit in den Ausbau der erneuerbaren Energien. Dass wir das auch garantiert tun, dafür stehen unter anderem das Grüner Strom-Label und der TÜV Nord. Wir denken global und handeln lokal. Und das wirkt wirklich gut!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/wirklich-veraendern",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Polarstern_ImpactgrafikPolarstern_GrafikenDetail_Investition.jpg",
        alt: "Investition vielerorts",
        width: "1920",
        height: "1200",
      },
    ],
  },
  {
    id: "investition",
    headline: "Zusätzliche Investition in die Energiewende.",
    text: "Wenig Energie zu verbrauchen, ist das Allerbeste. Aber wenn du's schon tust, soll es wenigstens etwas verändern. Deshalb investieren wir für jede Kilowattstunde, die du brauchst, Geld in den weiteren Ausbau von erneuerbaren Energien. Das wird sogar immer wichtiger. Denn durch den Wegfall der EEG-Umlage unterstützt ein klassischer Ökostromtarif ohne diesen Zubaueffekt gar nicht mehr die Energiewende. Wenn du also mit deiner Entscheidung für Ökostrom deinen Beitrag zur Energiewende leisten willst, bist du bei uns goldrichtig. Im Vergleich investieren wir pro Kund:in mehr als andere Energieversorger. Und so gibt's dank unserer Kund:innen immer mehr wirklich bessere Energie im Markt. Danke!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/energiewende-weltweit/",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Berlin-Adlershof_2018_03_Copyright_GSW_Sigmaringen_GmbH_1800.jpg",
        alt: "Berlin-Adlershof",
        width: "1800",
        height: "1200",
      },
      {
        src: "/impact-calculator/Luftbild_Innenhof-Bela-2_1800.jpg",
        alt: "Innenhof Bela",
        width: "1800",
        height: "1009",
      },
      {
        src: "/impact-calculator/Mieterstrom_Aubing_Copyright_Polarstern_1800.jpg",
        alt: "Mieterstrom Aubing",
        width: "1800",
        height: "1192",
      },
      {
        src: "/impact-calculator/Zusaetzliche_Investition_Energiewende_1800.jpg",
        alt: "Investition Energiewende",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "landschaft",
    headline: "Nachhaltig Energie erzeugen und nutzen.",
    text: "Erst einmal vorneweg: Jede Art der Energiegewinnung hat Einfluss auf die Umwelt. Doch mit der Art und Weise wie das erfolgt, können negative Folgen reduziert werden. Dazu gehören Effizienzmaßnahmen, um mit einem Kraftwerk mehr Energie zu erzeugen, genauso wie Maßnahmen, welche den Ressourcen- und Flächenbedarf reduzieren, wie PV-Anlagen auf großen Gebäudedächern (Stichwort Mieterstrom) oder Landschafts- und Tierschutzmaßnahmen. Unsere Partnerkraftwerke am Inn haben zum Beispiel Umgehungsgerinne für die Fische und neue Biotope und Vogelschutzgebiete errichtet. Nicht schnell, schnell umgesetzt – sondern sorgsam mit lokalen Umweltschutzvereinen und Fischerei-Biologen abgestimmt. So eine nachhaltige Energieerzeugung funktioniert, weil Kunden wie du nicht irgendeinen Ökostrom wollen, sondern unseren. Danke!",
    cta: {
      text: "YouTube-Video Wasserkraft",
      url: "https://www.youtube.com/watch?v=T0fo4TY9ymg",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Landschafts_Tierschutz_1800.jpg",
        alt: "Landschafts- und Tierschutz",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "photovoltaik",
    headline: "Du förderst auch Sonnenenergie.",
    text: "Mit jeder Kilowattstunde, die du verbrauchst, fördern wir neue Energiewendeprojekte. In den letzten Jahren haben wir mehrere hundert Photovoltaikprojekte zur dezentralen Energieversorgung realisiert – auf Mehrfamilienhäusern, Büro- und Wohnkomplexen, Firmendächern oder auch bei Veranstaltungsorten wie dem Olympiastadion Berlin. Das macht Sinn. Photovoltaikanlagen lassen sich schnell auf Dächern installieren. Sie bieten Platz, die man der Natur erst gar nicht abzwacken muss – und die Städter können so noch besser an der Energiewende teilhaben. So einfach geht's!",
    cta: {
      text: "Hier Beispiele ansehen",
      url: "https://polarstern-energie.de/mieterstrom/referenzen/",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Sonnenenergie_1_1800.jpg",
        alt: "Sonnenenergie 1",
        width: "1800",
        height: "949",
      },
      {
        src: "/impact-calculator/polarstern-solarkraft_olympiastadion_1.jpeg",
        alt: "Sonnenenergie 2",
        width: "1800",
        height: "1200",
      },
      {
        src: "/impact-calculator/polarstern-solarkraft_olympiastadion_2.jpeg",
        alt: "Sonnenenergie 3",
        width: "1800",
        height: "1200",
      },
    ],
  },
  {
    id: "ruebe",
    headline: "Rarität im Markt: 100 % echtes Ökogas.",
    text: "Unser Wirklich Ökogas ist der Ursprung von Polarstern. Wir haben es entwickelt, um die Energiewende endlich dort voranzubringen, wo wir Haushalte den Großteil ihrer Energie verbrauchen: beim Heizen. Inzwischen haben wir weitere Varianten von Wirklich Ökogas entwickelt, um noch mehr Haushalte mit wirklich besserer Energie beim Wärmen zu versorgen. Dabei ist eines sicher: Es ist immer 100 %-iges Biogas. Mit deinem Bezug von Wirklich Ökogas förderst du außerdem die Energiewende über das EEG hinaus. Und das ist verdammt wichtig: Denn nach wie vor ist der Anteil alternativer Energien im Wärmemarkt viel zu gering. Zusammen mit Polarstern änderst du das – sehr gut!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekogas/biogasanlage/",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Raritaet_im_Markt_1800.jpg",
        alt: "Rarität im Markt",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "waermepumpe",
    headline: "Heizstrom von Polarstern: Energiewende für die Wärmeerzeugung.",
    text: "Von wegen veraltet. Strom im Wärmemarkt erlebt eine Renaissance. Waren es lange Zeit vor allem alte Wohnungen mit Nachtspeicherheizungen, sind es heute Neubauten und sanierte Gebäude, die mit ihren Wärmepumpen Strom zum Heizen benötigen. Doch Stromheizungen benötigen viel Strom. Oft verdoppeln sie sogar den Strombedarf eines Haushalts. Umso wichtiger ist es, hier wirklich nachhaltig erzeugten Ökostrom zu nutzen. Wirklich Ökostrom aus 100 % deutscher Wasserkraft gibt es als Spezialtarif für Wärmepumpen, Nachtspeicherheizungen und HT/NT-Zähler. So leistest du einen starken Beitrag zur dringend nötigen Wärmewende.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekostrom/spezialtarife/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Heizstrom_1800.jpg",
        alt: "Heizstrom",
        width: "1800",
        height: "1200",
      },
    ],
  },
  {
    id: "strom",
    headline: "Wirklich Ökostrom: dein Schwert für den Klimaschutz.",
    text: "Ohne Strom läuft heute gar nix mehr. Und: Wir brauchen immer mehr davon – für immer mehr Geräte und Anlagen. Bei dieser Bedeutung des Stroms für unser Leben darf uns seine Qualität nicht egal sein. Wirklich Ökostrom ist dein Schwert für eine echte Energiewende und mehr Klimaschutz. Du verhinderst damit CO₂ und andere Treibhausgase, drängst die fossilen Energieträger in die Höhle zurück, schützt die Natur und förderst den Ausbau von Ökokraftwerken. Als unabhängiger Ökoenergieversorger haben wir von Polarstern keine Verbindung zu Kohle- und Atomkraftwerken und nur 100 % Erneuerbaren-Tarife im Angebot. So veränderst du wirklich etwas – zum Guten. Eh klar!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekostrom/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Wirklich_Oekostrom_1800.JPG",
        alt: "Wirklich Oekostrom",
        width: "1800",
        height: "1350",
      },
    ],
  },
  {
    id: "nachtspeicher",
    headline: "Heizstrom von Polarstern: Energiewende für die Wärmeerzeugung.",
    text: "Von wegen veraltet. Strom im Wärmemarkt erlebt eine Renaissance. Waren es lange Zeit vor allem alte Wohnungen mit Nachtspeicherheizungen, sind es heute Neubauten und sanierte Gebäude, die mit ihren Wärmepumpen Strom zum Heizen benötigen. Doch Stromheizungen benötigen viel Strom. Oft verdoppeln sie sogar den Strombedarf eines Haushalts. Umso wichtiger ist es, hier wirklich nachhaltig erzeugten Ökostrom zu nutzen. Wirklich Ökostrom aus 100 % deutscher Wasserkraft gibt es als Spezialtarif für Wärmepumpen, Nachtspeicherheizungen und HT/NT-Zähler. So leistest du einen starken Beitrag zur dringend nötigen Wärmewende.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekostrom/spezialtarife/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Heizstrom_1800.jpg",
        alt: "Heizstrom",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "mieterstrom",
    headline: "Wirklich Mieterstrom fördert die urbane Energiewende.",
    text: "54 % der Deutschen wohnen in Mehrparteienhäusern. Während die Dörfer kleiner werden, quellen die Städte über. Mit Wirklich Mieterstrom bringen wir die dezentrale Energiewende in die Stadtwohnungen. Mehrparteien- und Geschäftshäuser erzeugen saubere Energie – und ihre Bewohner nutzen sie. Das senkt die Nebenkosten, wertet die Häuser auf und macht die Städte fit für die Zukunft. Jeder Mieterstrom-Kunde ist ein wichtiger Teil der Energiewende. Nur wenn die Energiewende bei allen ankommt, kann sie uns gelingen. Aus Leuchtturmprojekten machen wir Standards.",
    cta: {
      text: "Mehr erfahren",
      url: "Link: https://www.polarstern-energie.de/mieterstrom/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Wirklich_Mieterstrom_1800_output.jpg",
        alt: "Wirklich Mieterstrom",
        width: "1800",
        height: "1312",
      },
    ],
  },
  {
    id: "htnt",
    headline: "Heizstrom von Polarstern: Energiewende für die Wärmeerzeugung.",
    text: "Von wegen veraltet. Strom im Wärmemarkt erlebt eine Renaissance. Waren es lange Zeit vor allem alte Wohnungen mit Nachtspeicherheizungen, sind es heute Neubauten und sanierte Gebäude, die mit ihren Wärmepumpen Strom zum Heizen benötigen. Doch Stromheizungen benötigen viel Strom. Oft verdoppeln sie sogar den Strombedarf eines Haushalts. Umso wichtiger ist es, hier wirklich nachhaltig erzeugten Ökostrom zu nutzen. Wirklich Ökostrom aus 100 % deutscher Wasserkraft gibt es als Spezialtarif für Wärmepumpen, Nachtspeicherheizungen und HT/NT-Zähler. So leistest du einen starken Beitrag zur dringend nötigen Wärmewende.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekostrom/spezialtarife/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Heizstrom_1800.jpg",
        alt: "Wirklich Heizstrom",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "gas",
    headline: "Wirklich Ökogas: verändern, worauf's ankommt.",
    text: "80 % der Energie geht beim Heizen, Duschen und Baden drauf. Doch der Anteil der erneuerbaren Energien bei der Wärmeversorgung ist mager: 2019 lag er in Deutschland nicht mal bei 15 %. Nur ein Bruchteil von 3 % aller Biogasprodukte stammt zu 100 % aus erneuerbaren Energien! Mit deiner Entscheidung für Polarstern gibst du der Wärmewende den richtigen Push: Ökogas aus organischen Reststoffen statt aus Monokulturen, Tiergülle oder grün gewaschenem Erdgas.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekogas/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Wirklich_Oekogas_1800.jpg",
        alt: "Wirklich Ökogas",
        width: "1800",
        height: "1201",
      },
    ],
  },
  {
    id: "eigenstrom",
    headline: "Wirklich Eigenstrom: Partner für deine Unabhängigkeit.",
    text: "Eigenstrom ist eine tolle Sache. Die Stromversorgung aus einer eigenen Photovoltaikanlage ist günstiger als Strom aus dem öffentlichen Netz. Sie macht dich unabhängig von steigenden Energiepreisen und unterstützt die Energiewende von unten. Doch nicht immer erzeugst du ausreichende Mengen an Strom. Gerade abends und nachts oder im Winter wird's manchmal eng. Aber auch dann brauchst du keine Abstriche bei der Ökoqualität zu machen. Wirklich Eigenstrom macht deine Versorgung zu 100 % öko. Du kannst den Tarif zu jeder PV-Anlage dazubuchen. Abgerechnet wird rein nach Verbrauch, ohne Grundgebühr. Einfacher geht's kaum.",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/eigenstrom/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Wirklich_Eigenstrom_1800.jpg",
        alt: "Wirklich Eigenstrom",
        width: "1800",
        height: "1349",
      },
    ],
  },
  {
    id: "autostrom",
    headline: "Wirklich Autostrom: richtig sauber fahren.",
    text: "Der Verkehr verursacht immer mehr statt immer weniger Treibhausgas-Emissionen. Mit neuen Antrieben wie der Elektromobilität ändert sich das, sofern die E-Autos auch mit Ökostrom fahren. Erst mit 100 % Ökostrom in der Batterie wird die Elektromobilität zu einer sauberen Sache. Dafür gibt es spezielle Tarife wie Wirklich Autostrom und Wirklich Autostrom Plus von Polarstern. Und Firmen und Immobilienbesitzende unterstützen wir beim Angebot von klimabewussten Ladelösungen vor Ort.  So fährst du CO₂-frei, animierst andere zum Umdenken und lässt die fossile Energie hinter dir. Vroom!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/elektroauto-stromtarif/",
    },
    color: "#bed75a",
    images: [
      {
        src: "/impact-calculator/Wirklich_Autostrom_1_1800.jpg",
        alt: "Wirklich Autostrom 1",
        width: "1800",
        height: "1013",
      },
      {
        src: "/impact-calculator/Wirklich_Autostrom_2_1800.jpg",
        alt: "Wirklich Autostrom 2",
        width: "1800",
        height: "1013",
      },
    ],
  },
  {
    id: "wasserkraft",
    headline: "Wasserkraft gibt's wirklich nachhaltig.",
    text: "Meist stammt Ökostrom aus Wasserkraft. Auch bei uns steckt vor allem Wasserkraft darin, ergänzt durch immer mehr Solarenergie. Doch wie beim Schinken oder bei den Lederschuhen auch, ist es eben nicht egal, woher der Strom aus Wasserkraft kommt. Wirklich Ökostrom von Polarstern stammt aus zwei Laufwasserkraftwerken am bayerischen Inn. Warum von dort? Weil die Kraftwerke in Sachen Umwelt- und Tierschutz zur Spitze gehören. Sie sind u.a. mit dem EKOenergy-Label zertifiziert. Wir sind überzeugt, dass es neben dem Bau neuer Kraftwerke, eine effiziente Nutzung der bestehenden Kraftwerke braucht. Dazu pflegen wir einen engen Austausch mit den Betreibern und besuchen die Kraftwerke jährlich mit unseren Kunden. Überzeug dich selbst!",
    cta: {
      text: "Mehr erfahren",
      url: "https://www.polarstern-energie.de/oekostrom/wasserkraftwerk/",
    },
    color: "#6ec8dc",
    images: [
      {
        src: "/impact-calculator/Wasserkraft_1800.jpg",
        alt: "Wasserkraft",
        width: "1800",
        height: "1201",
      },
    ],
  },
];
