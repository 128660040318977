<template>
  <TransitionGroup name="recommendation" appear>
    <g v-for="index in graph.recommendations.length" :key="index">
      <!-- recommendation-line -->
      <path
        class="recommendation-line-move"
        :d="`M 90,90 L
              ${graph.recommendations[index - 1].x},
              ${graph.recommendations[index - 1].y}`"
        stroke-width="2"
        stroke-opacity="0.3"
        :stroke="mode === 'light' ? '#000000' : '#FFFFFF'"
      />
      <!-- recommendation -->
      <RecommendationChartAvatar
        :x="graph.recommendations[index - 1].x"
        :y="graph.recommendations[index - 1].y"
        :gender="index % 2 === 0 ? 'female' : 'male'"
      />
    </g>
  </TransitionGroup>
</template>

<script setup lang="ts">
import RecommendationChartAvatar from "./RecommendationChartAvatar.vue";
const impactStore = useImpactCalculatorStore();
const { graph } = storeToRefs(impactStore);
const mode = useColorMode();
</script>

<style lang="scss">
@import "@/assets/scss/components/03_organisms/impact-calculator-animations.scss";

.recommendation-enter-active,
.recommendation-leave-active {
  transition: var(--transition-quick) opacity ease-out;
}

.recommendation-enter-from,
.recommendation-leave-to {
  opacity: 0;
}
</style>
