<template>
  <component :is="type" :id="id" :class="[size]" @click="headlineAction">
    <slot>{{ text }}</slot>
    <i :class="['ui-icon', `icon-${toggleIcon}`]"></i>
  </component>
</template>

<script setup lang="ts">
defineProps({
  id: { type: String, default: "" },
  size: { type: String, default: "" },
  text: { type: String, default: "" },
  type: { type: String, default: "h1" },
});

const toggleIcon = ref("caret-down");
const emit = defineEmits(["headlineAction"]);

const headlineAction = () => {
  toggleIcon.value = toggleIcon.value === "caret-up" ? "caret-down" : "caret-up";

  emit("headlineAction");
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/01_atoms/headline";

.ui-icon {
  --border: 0.125rem solid;
  --icon-size: 1.5rem;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: var(--icon-size);
  width: var(--icon-size);

  &::before,
  &::after {
    content: "";
    background: transparent;
    display: block;
    position: absolute;
  }

  &.icon-caret-up,
  &.icon-caret-down {
    border: none;

    &::after {
      border-left: var(--border);
      width: 0.625rem;
      height: 0.625rem;
      left: 0.5rem;
      margin-top: 50%;
    }
  }

  &.icon-caret-up {
    &::after {
      border-top: var(--border);
      transform: rotate(45deg);
    }
  }

  &.icon-caret-down {
    &::after {
      border-bottom: var(--border);
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}
</style>
