import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m33.15 49 .26.22.2 1.06c.76 4 1.22 6.47 3.68 9.94 2.92 4.1 6.12 7 9 8.1a8.3 8.3 0 0 0 3.32.46h.74a8.3 8.3 0 0 0 3.32-.46c2.9-1.12 6.1-4 9-8.1 2.46-3.47 2.92-5.91 3.68-10l.2-1 .26-.22a9.12 9.12 0 0 0 3.7-8 2.56 2.56 0 0 0-2.39-2.21c-.46 0-1.86 0-1.87 3a20.7 20.7 0 0 0 .75 5l-1.58 1c-.52-.79-.83-3.31-1-5.91-.1-1.6-.15-3.22-.15-4.48V37l.27-.27a5.52 5.52 0 0 0 1.14-3.64c0-3.74-1-5.72-3.42-6.64-5.36 7-14.26 8.2-20.19 9-3.24.42-6.3.83-6.3 2 0 3.35-.31 9.16-1.15 10.43l-.79-.52-.83-.57a27.4 27.4 0 0 0 .78-6.67 2 2 0 0 0-2-1.63 2.51 2.51 0 0 0-2.21 1.26A9.08 9.08 0 0 0 33.15 49" }, null, -1),
    _createElementVNode("path", { d: "M29.38 37.86c0 .09 0 .15.05.22a3.64 3.64 0 0 1 2.41-.85 3.27 3.27 0 0 1 2 .68v-.52c0-2.79 3.5-3.25 7.94-3.83 6.11-.8 14.47-1.9 19.31-8.76a29.4 29.4 0 0 0 2.34-4.22l1.73.75a29 29 0 0 1-1.88 3.53c3.77 1.68 4.25 5.44 4.25 8.23a7.9 7.9 0 0 1-1.17 4.27 3.8 3.8 0 0 1 1.78-.44 3.92 3.92 0 0 1 2.48.82c.32-1.88.87-5.22 0-7.19l-.13-.29.08-.31c1-4.18-.91-9.38-4-10.7l-.42-.25-.09-.5c-.76-4.29-7.36-7.32-16.06-7.32a21.36 21.36 0 0 0-15.62 6.29 16.54 16.54 0 0 0-4.44 12.65v.21c-.23 1.34-.94 5.42-.56 7.53M37.69 71c1.87 5.53 7.86 14.22 12.31 15.3 4.45-1.08 10.44-9.78 12.32-15.3-1.32-.93-2-2.75-2.41-4.64a17.7 17.7 0 0 1-5.53 3.74 9.5 9.5 0 0 1-3.48.6h-1.22a10.1 10.1 0 0 1-4.06-.59 17.7 17.7 0 0 1-5.53-3.74c-.45 1.89-1.09 3.72-2.4 4.63" }, null, -1),
    _createElementVNode("path", { d: "m35.07 70.06 1.29-.45c1.17-.46 1.72-2.81 2.16-4.85a37 37 0 0 1-2.77-3.43c-2.7-3.8-3.22-6.54-4-10.68l-.07-.39a10.8 10.8 0 0 1-3.88-11 8 8 0 0 1-.28-1.11c-.37-2.14.12-5.6.53-8.06A18.4 18.4 0 0 1 33 16.17a23.3 23.3 0 0 1 17-6.88c9.49 0 16.55 3.37 17.82 8.44 3.56 1.9 5.73 7.57 4.69 12.37.93 2.51.33 6 0 8.08a8 8 0 0 1-.28 1.11 10.8 10.8 0 0 1-3.88 11l-.07.38c-.78 4.15-1.3 6.89-4 10.69a37 37 0 0 1-2.77 3.43c.44 2 1 4.39 2.19 4.86l1.24.43 26.36 8.17a50 50 0 1 0-82.54 0Z" }, null, -1),
    _createElementVNode("path", { d: "m35.66 71.85-.58.2-25.2 7.79a50 50 0 0 0 80.24 0L64.93 72l-.59-.2-.27-.09c-2 5.55-8.28 15.33-13.88 16.43h-.36c-5.6-1.1-11.91-10.88-13.88-16.43Z" }, null, -1)
  ])))
}
export default { render: render }