export default function (recommendationsCount: number) {
  const distance = 1000;
  const x = 0;
  const y = -distance + distance * Math.random() * 0.2;

  const positions = [];

  for (let idx = 0; idx < recommendationsCount; idx++) {
    let angle = ((Math.PI * 2) / recommendationsCount) * idx + 1.8;
    angle += angle * Math.random() * 0.15;

    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const tx = x * cos - y * sin + 0;
    const ty = x * sin + y * cos + 0;

    positions.push({ x: tx, y: ty });
  }

  return positions;
}
