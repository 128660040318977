import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M33.23 70.63c3.4-1 5.28-1.63 6.26-3.14.76-1.19 1-3.84 1.1-5.14-1-1.19-2-2.48-2.9-3.69C35.58 57 26.42 49 26.42 33.09c0-14.53 9.25-23.91 23.58-23.91 15.06 0 24 8.94 24 23.91 0 6.67-1.45 9.74-2.51 12a6.9 6.9 0 0 0-.88 2.91 6.6 6.6 0 0 0 .81 3.21 7 7 0 0 1 .85 3.24 7.24 7.24 0 0 1-.87 3.33 5.6 5.6 0 0 0-.72 2.69 4.94 4.94 0 0 0 1.17 2.79 8 8 0 0 1 1.77 4.83 12.6 12.6 0 0 1-.7 4.45l18.35 5.69a50 50 0 1 0-82.54 0Z" }, null, -1),
    _createElementVNode("path", { d: "M33.32 38.71c-.23 0-.93 0-.93 2.24 0 3.8 3.48 6.51 3.51 6.53l.32.25v.39c.52 4.17 1.49 7.31 2.89 9.34 3.32 4.64 6.33 7.5 8.07 7.66a27.7 27.7 0 0 0 5.56 0c2.24-.21 5.29-4.06 7.31-6.61l.24-.3.51-.75c1.4-2 2.37-5.17 2.88-9.34l.05-.39.32-.25s3.52-2.76 3.52-6.53c0-2.24-.7-2.24-.93-2.24-.38 0-1.09 0-1.09 1.46v3.75H63.7V38.7c0-4.24-.82-9.32-3.09-10.67C55.94 37.37 49 37.34 44 37.32c-2.64 0-4.94 0-6.21 1.24-1 1-1.44 2.72-1.44 5.36h-1.94v-3.75c0-1.46-.71-1.46-1.09-1.46" }, null, -1),
    _createElementVNode("path", { d: "M33.78 72.43h-.13C36 74.62 42.11 79.31 50 79.31a23.83 23.83 0 0 0 13.3-4.43 10.51 10.51 0 0 1-4.92-9.14 21 21 0 0 1 .13-2.38C56.74 65.27 54.84 66.83 53 67a29 29 0 0 1-5.9 0c-1.47-.14-3.12-1.27-4.74-2.83a10.8 10.8 0 0 1-1.24 4.34c-1.4 2.09-3.76 2.82-7.34 3.92" }, null, -1),
    _createElementVNode("path", { d: "M9.88 79.84a50 50 0 0 0 80.24 0L71.32 74l-1.2-.38.71-1a9.1 9.1 0 0 0 .9-4.5 6.32 6.32 0 0 0-1.45-3.78 6.64 6.64 0 0 1-1.49-3.84 7.45 7.45 0 0 1 .9-3.49 5.4 5.4 0 0 0 .69-2.53 5.2 5.2 0 0 0-.67-2.48 8.4 8.4 0 0 1-1-4 8.5 8.5 0 0 1 1.11-3.74c1-2.08 2.33-4.92 2.33-11.17 0-14-8.08-22-22.16-22-13.38 0-21.69 8.44-21.69 22 0 10.25 4 17 7.17 20.77a33.5 33.5 0 0 1-1-5.14c-1-.86-3.94-3.76-3.94-7.77 0-3.59 1.76-4.13 2.82-4.13a2.76 2.76 0 0 1 2.4 1.18 5 5 0 0 1 .66-.8c1.83-1.8 4.59-1.79 7.59-1.77 5.24 0 11.16 0 15.32-9l.33-.7.75.18c4.06.94 5 7.16 5.19 11.08a3.4 3.4 0 0 1 1.13-.18c1.06 0 2.82.54 2.82 4.13 0 4-3 6.91-4 7.77-.57 4.35-1.61 7.57-3.16 9.82-1.35 2-2.12 3.25-2.12 7.2a8.57 8.57 0 0 0 5.14 8.14l1.58.61-1.34 1a26.57 26.57 0 0 1-14.71 5.64v9.63h-1.87v-9.59c-9.2-.39-15.78-6.33-17.48-8Z" }, null, -1)
  ])))
}
export default { render: render }