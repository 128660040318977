import { format, subDays } from "date-fns";

export const getNewDateWithYearDelta = (delta: number) => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + delta)).toISOString();
};

export const thisYear = () => new Date().toISOString();
export const nextYear = () => getNewDateWithYearDelta(1);
export const oneYearAgo = () => getNewDateWithYearDelta(-1);
export const threeYearsAgo = () => getNewDateWithYearDelta(-3);
export const fiveYearsAgo = () => getNewDateWithYearDelta(-5);
export const tenYearsAgo = () => getNewDateWithYearDelta(-10);

/** Use this function whenever we want to set the value of a <input type="date"> element. */
export const dateToDateString = (date: Date) => format(date, "yyyy-MM-dd");

/**
 * Subtract a fraction of a year from the current date.
 * E.g. if decimalYears is 0.5, the date returned will be 6 months ago.
 *
 * @param fraction The fraction of a year to subtract from the current date.
 * @returns The date that is `fraction` fraction of a year ago.
 */
export const subtractFractionOfYearFromNow = (fraction: number): Date => {
  const daysInYear = 365.25; // Average days in a year accounting for leap years
  const daysToSubtract = fraction * daysInYear;
  return subDays(new Date(), daysToSubtract);
};
