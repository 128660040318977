<template>
  <div class="tariff-panel--wrapper">
    <TariffPanelCustomerTariffs
      :is-customer-logged-in="isCustomerLoggedIn"
      :tariffs="activeTariffs"
    />
    <TariffPanelAllTariffs
      :is-customer-logged-in="isCustomerLoggedIn"
      :tariffs="impactContractTemplates"
    />
  </div>
</template>

<script setup lang="ts">
import TariffPanelCustomerTariffs from "./TariffPanelCustomerTariffs.vue";
import TariffPanelAllTariffs from "./TariffPanelAllTariffs.vue";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";
import { useImpactCalculatorStore } from "~/stores/impact-calculator";

const props = defineProps<{
  isCustomerLoggedIn?: boolean;
  impactContracts: AlternativeImpactContract[];
}>();

const activeTariffs = computed(() =>
  props.impactContracts.filter((c) => c.state === "Active"),
);

const impactStore = useImpactCalculatorStore();
const { impactContractTemplates } = impactStore;
</script>

<style lang="scss">
.tariff-panel--wrapper {
  gap: calc(var(--gap) / 2);
  width: 100%;

  .tariff-panel--selection-wrapper {
    padding: 0 10px;
    margin-bottom: 1rem;

    .h4 {
      margin-bottom: var(--gap);
    }

    .tariff-selection {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;

      @include breakpoint-from(small) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    }

    .pointer {
      cursor: pointer;
    }
  }
}
</style>
