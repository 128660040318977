<template>
  <g
    transform="scale(0.4) translate(75 75)"
    class="graph-icon"
    :class="`icon-color--${iconColor}`"
    tabindex="0"
    role="button"
    :aria-label="headline"
    @click="$emit('graph-icon-click', name)"
    @mouseover="
      (e) => {
        hoverEffect = true;
        $emit('mouseover', e);
      }
    "
    @mouseleave="
      (e) => {
        hoverEffect = false;
        $emit('mouseleave', e);
      }
    "
    @keydown="
      (e) => {
        if (e.code === 'Space') $emit('graph-icon-click', name);
      }
    "
  >
    <circle
      id="bg-circle"
      fill="transparent"
      :class="[{ ripple: hoverEffect }, hoverEffect && `ripple-color-${iconColor}`]"
      cx="50"
      cy="50"
      r="40"
    ></circle>

    <use x="0" y="0" :href="`#${name}`" :fill="iconColorMapping[iconColor]" />
  </g>
</template>

<script setup lang="ts">
defineProps<{
  iconColor: "green" | "blue" | "yellow";
  name: string;
  headline: string;
}>();

const iconColorMapping = {
  green: "#bed75a",
  blue: "#6ec8dc",
  yellow: "#ffd750",
};

const hoverEffect = ref(false);

defineEmits(["graph-icon-click", "mouseover", "mouseleave"]);
</script>

<style scoped lang="scss">
$ripple-green: #bed75a;
$ripple-blue: #6ec8dc;
$ripple-yellow: #ffd750;

g {
  cursor: pointer;

  #bg-circle {
    transition: var(--transition-standard) all ease-in-out;
    stroke: transparent;
  }

  .ripple {
    transition: var(--transition-standard) all ease-in-out;
    // TODO: What is r? radius?
    r: 75;
    stroke-width: 5;
    fill-opacity: 0.3;
  }

  .ripple-color-green {
    fill: $ripple-green !important;
    stroke: $ripple-green !important;
  }
  .ripple-color-blue {
    fill: $ripple-blue !important;
    stroke: $ripple-blue !important;
  }
  .ripple-color-yellow {
    fill: $ripple-yellow !important;
    stroke: $ripple-yellow !important;
  }
}
</style>
