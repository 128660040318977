<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="1820"
    height="1820"
    :x="graph.settings.x"
    :y="graph.settings.y"
    :style="{ backgroundColor: mode === 'light' ? 'white' : 'black' }"
    @mousedown="initDragging"
    @mousemove="dragging"
    @mouseup="stopDragging"
    @mouseleave="stopDragging"
    @touchstart="initDragging"
    @touchend="stopDragging"
    @touchmove.prevent="dragging"
  >
    <rect
      width="1820"
      height="1820"
      :fill="mode === 'light' ? 'white' : 'black'"
      :x="-graph.settings.x"
      :y="-graph.settings.y"
    />
    <FlowerIcons class="hidden" />
    <g class="graph-zoom" :transform="initialTransformScale">
      <g :transform="initialTransformPosition">
        <slot />
      </g>
    </g>
    <LogoIcon x="20" y="470" class="hidden" />
  </svg>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import FlowerIcons from "~/assets/icons/impact-calculator/flower-icons.svg?component";
import LogoIcon from "~/components/02_molecules/impact-calculator/LogoIcon.vue";
import { useImpactCalculatorStore } from "~/stores/impact-calculator";

const mode = useColorMode();

const impactStore = useImpactCalculatorStore();
const { graph } = storeToRefs(impactStore);

const allowDragging = ref(false);
const mousePosition = ref({ x: 0, y: 0 });
const recordPosition = ref({
  x: graph.value.settings.x,
  y: graph.value.settings.y,
});

const initialTransformPosition = computed(() => {
  return `translate(${graph.value.settings.x} ${graph.value.settings.y})`;
});
const initialTransformScale = computed(() => {
  return `scale(${graph.value.settings.zoom})`;
});

const initDragging = (evt: MouseEvent | TouchEvent) => {
  allowDragging.value = true;

  if (evt instanceof MouseEvent) {
    mousePosition.value.x = evt.clientX;
    mousePosition.value.y = evt.clientY;
  } else if (evt instanceof TouchEvent && evt.touches.length > 0) {
    mousePosition.value.x = evt.touches[0].clientX;
    mousePosition.value.y = evt.touches[0].clientY;
  }
};

const dragging = (evt: MouseEvent | TouchEvent) => {
  if (!allowDragging.value) return;

  if (evt instanceof MouseEvent) {
    graph.value.settings.x =
      recordPosition.value.x + (evt.clientX - mousePosition.value.x);
    graph.value.settings.y =
      recordPosition.value.y + (evt.clientY - mousePosition.value.y);
  } else if (evt instanceof TouchEvent && evt.touches.length > 0) {
    graph.value.settings.x =
      recordPosition.value.x + (evt.touches[0].clientX - mousePosition.value.x);
    graph.value.settings.y =
      recordPosition.value.y + (evt.touches[0].clientY - mousePosition.value.y);
  }
};

const stopDragging = () => {
  allowDragging.value = false;

  recordPosition.value.x = graph.value.settings.x;
  recordPosition.value.y = graph.value.settings.y;
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/components/03_organisms/impact-calculator-animations.scss";

#impact-graph {
  position: relative;
  overflow: hidden;

  & > svg {
    display: inline;
    height: 70vh;
    width: 100%;
    cursor: grab;

    transition: var(--transitions-all-standard);

    &:active,
    &:focus-visible {
      cursor: grabbing;
    }
  }

  .share--component {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}
</style>
