<template>
  <div id="impact-graph" aria-label="Auflistung der unterschiedlichen Wirkungsfelder">
    <ImpactChartWrapper>
      <RecommendationChart />
      <FlowerInstance @modal-open="() => modal?.show()" />
    </ImpactChartWrapper>
    <GraphZoom />
    <!-- <GraphDownload /> -->
    <GraphShare />
    <TheModal ref="modal" :title="content?.headline">
      <ImpactGraphModalContent v-if="!!content" :content="content" />
    </TheModal>
  </div>
</template>

<script setup lang="ts">
import { modalContent } from "./modalContent";
import FlowerInstance from "~/components/02_molecules/impact-calculator/FlowerInstance.vue";
import GraphZoom from "~/components/02_molecules/impact-calculator/GraphZoom.vue";
import RecommendationChart from "~/components/02_molecules/impact-calculator/RecommendationChart.vue";
import GraphShare from "~/components/02_molecules/impact-calculator/GraphShare.vue";
import ImpactChartWrapper from "~/components/02_molecules/impact-calculator/ImpactChartWrapper.vue";
import TheModal from "~/components/01_atoms/TheModal.vue";
import ImpactGraphModalContent from "~/components/03_organisms/impact-calculator/ImpactGraphModalContent.vue";

const impactStore = useImpactCalculatorStore();
const modal = ref<InstanceType<typeof TheModal>>();
const content = computed(() => {
  return modalContent.find((c) => c.id === impactStore.getActiveIconForGraphModal());
});
</script>

<style lang="scss">
@import "@/assets/scss/components/03_organisms/impact-calculator-animations.scss";

#impact-graph {
  position: relative;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-left: none;
  border-right: none;

  & > svg {
    display: inline;
    height: 70vh;
    width: 100%;
    cursor: grab;

    transition: var(--transitions-all-standard);

    &:active,
    &:focus-visible {
      cursor: grabbing;
    }
  }
}
</style>
